import { useEffect, useState } from "react";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useChatStore } from "./chatStore";
import Chat from "./Chat";
import "./chatList.css";
import { Dialog, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import back arrow icon
import ChatIcon from '@mui/icons-material/Chat'; // For messaging icon

const ChatList = () => {
  const [userChats, setUserChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null); // To track the selected chat
  const [open, setOpen] = useState(false); // For modal open/close
  const { changeChat } = useChatStore(); // Chat state handler

  const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
  const uid = userFromLocalStorage ? userFromLocalStorage.uid : null;

  const getInitials = (name) => {
    if (!name) return "U";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.slice(0, 2).toUpperCase();
  };

  const fetchUserDetails = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const userSnap = await getDoc(userDocRef);

    if (userSnap.exists()) {
      const { firstname, lastname } = userSnap.data();
      return `${firstname} ${lastname}`;
    }
    return "User";
  };

  useEffect(() => {
    if (!uid) return;

    const unSub = onSnapshot(doc(db, "userchats", uid), async (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (data && data.chats) {
          const chatDataWithNames = await Promise.all(
            data.chats.map(async (chat) => {
              const fullName = await fetchUserDetails(chat.receiverId);
              return { ...chat, fullName };
            })
          );
          setUserChats(chatDataWithNames);
        }
      }
    });

    return () => unSub();
  }, [uid]);

  const handleSelect = (chat) => {
    // Select a chat and trigger the chat change logic
    setSelectedChat(chat);
    changeChat(chat.chatId, chat.receiverId);
  };

  const handleBack = () => {
    // Deselect the chat and go back to the chat list
    setSelectedChat(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChat(null); // Reset selected chat when closing
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {/* Chat Modal */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Box p={3} display="flex" flexDirection="column" style={{ height: "100%", overflow: "hidden" }}>
          {/* Header */}
          <Box
            display="flex"
            justifyContent="space-between" // Adjust alignment
            alignItems="center"
            mb={2}
          >
            {/* Conditionally render back button */}
            {selectedChat ? (
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            ) : null}
            <Typography
              variant="h6"
              style={{
                fontFamily: "Outfit",
                flex: selectedChat ? 1 : "none", // Center if chat selected
                textAlign: selectedChat ? "center" : "left", // Adjust text alignment
                marginLeft: selectedChat ? 0 : "16px", // Add margin when left-aligned
              }}
            >
              Inbox
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Chats List or Selected Chat */}
          <Box
            style={{
              height: "calc(100% - 56px)", // Adjust height based on header
              overflowY: "auto",
            }}
          >
            {selectedChat ? (
              // Render selected chat
              <Chat chatId={selectedChat.chatId} otherUserID={selectedChat.receiverId} />
            ) : (
              // Render chat list
              <div className="messages-list">
                {userChats.length > 0 ? (
                  userChats.map((chat) => (
                    <div
                      className={`chat-item ${!chat?.isSeen ? "unread" : ""}`}
                      key={chat.chatId}
                      onClick={() => handleSelect(chat)} // Handle chat selection
                    >
                      <div className="avatar">
                        <span className="initials">{getInitials(chat.fullName)}</span>
                      </div>
                      <div className="chat-content">
                        <div className="chat-header">
                          <span className="username">{chat.fullName || "User"}</span>
                          <span className="timestamp">
                            {new Date(chat.updatedAt).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                          </span>
                        </div>
                        <p className="last-message">{chat.lastMessage}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <Box p={2}>
                    <Typography variant="h6" style={{ fontFamily: "Outfit", color: "#555", fontWeight: 500 }}>
                      You haven't begun messaging anyone yet.
                    </Typography>
                    <Typography variant="body2" style={{ fontFamily: "Outfit", color: "#888", marginTop: 8 }}>
                      Start a conversation to see your chats here. Click on the "interested" button when viewing a room to start a conversation.
                    </Typography>
                  </Box>
                )}
              </div>
            )}
          </Box>
        </Box>
      </Dialog>

      {/* Floating Action Button */}
      <div
        onClick={handleOpen}
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
          width: 56,
          height: 56,
          backgroundColor: "#388E3C",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
          cursor: "pointer",
        }}
      >
        <ChatIcon sx={{ color: '#FFFFFF' }}/>
      </div>
    </>
  );
};

export default ChatList;
