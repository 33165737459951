// DeleteRoom.js
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../apis/backend";
import "../styles/DeleteRoom.css";

function DeleteRoom() {
    const { roomId } = useParams();
    const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
    const uID = userFromLocalStorage ? userFromLocalStorage.uid : null;
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        axiosInstance.get(`/deleteRoom/${uID}/${roomId}`)
        navigate('/user/my-houses');     
    };

    const goBack = (e) => {
        e.preventDefault();
        navigate('/user/my-houses');
    };

    return (
        <div className="delete-room-container">
            <div className="delete-room-card">
                <div className="delete-room-header">
                    <h2>Delete Posting</h2>
                </div>
                <div className="delete-room-content">
                    <div className="warning-icon">
                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                            <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </div>
                    <p>Are you sure you want to delete this posting?</p>
                    <p className="delete-room-subtitle">This action cannot be undone.</p>
                </div>
                <div className="delete-room-actions">
                    <button className="btn-secondary" onClick={goBack}>Cancel</button>
                    <button className="btn-danger" onClick={handleSubmit}>Delete</button>
                </div>
            </div>
        </div>
    );
}

export default DeleteRoom;