
import {
  AppBar,
  Box,
  styled,
  Toolbar,
  Typography,
  InputBase,
  Grid,
  Container,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RoomCard from "../../components/RoomCard";
import MenuIcon from '@mui/icons-material/Menu';
import FilterListIcon from "@mui/icons-material/FilterList";
import "../../styles/Navbar.css";
import { AuthContext } from "../../contexts/AuthContext";
import { auth } from "../../firebase";
import { Navigate, Link } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import axiosInstance from "../../apis/backend";
import Logo from '../../images/logo.png';  
import "../../styles/Navbar.css"


const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

const PlainNavbar = () => {

  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [savedRooms, setSavedRooms] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [address, setAddress] = useState("");
  const { currentUser, dispatch } = useContext(AuthContext);
  const [profileBar, setProfileBar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const showProfileBar = () => setProfileBar(!profileBar);
  const [anchorEl, setAnchorEl] = useState(null); 
  const getUserInitials = (name) => {
    if (!name) return "P";
    const names = name.split(' ');
    if (names.length === 1) return names[0].charAt(0).toUpperCase();
    return (names[0].charAt(0) + names[names.length - 1].charAt(0)).toUpperCase();
  };
  
  const userInitials = getUserInitials(currentUser?.displayName);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" }); 
    auth
      .signOut(currentUser)
      .then(() => {
        console.log("Logged out");
        localStorage.removeItem("user");
        dispatch({ type: "LOGOUT", payload: null });
        <Navigate to="/login" />;
      })
      .catch((error) => {
        console.log("There is an error");
      });
    
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'green[700]' }}> 

      <StyledToolbar>
        <Box display="flex" alignItems="center">
          <Link to="/">
            <img
              src={Logo}
              alt="Logo"
              style={{ width: '50px', height: '50px', borderRadius: '5px' }}
            />
          </Link>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Typography variant="h6" style={{ marginLeft: '10px', fontFamily: 'Outfit', color: '#f5f5f5' }}>

              SUBLEASE
            </Typography>
          </Link>
        </Box>
        <Box display="flex" alignItems="center">
            
            <Box display="flex" alignItems="center" style={{ marginRight: '15px' }}>
              <Typography
                onClick={handleMenuClick}
                style={{
                  color: '#f5f5f5',
                  fontFamily: 'Outfit',
                  fontSize: '14px',
                  cursor: 'pointer',
                  marginRight: '0px'
                }}
              >
                INFO
              </Typography>
              <KeyboardArrowDownIcon
                onClick={handleMenuClick}
                style={{ color: '#f5f5f5', cursor: 'pointer' }} 
              />
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/about" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Outfit' }}>About</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/feedback" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Outfit' }}>Give Us Feedback</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/contribute" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Outfit' }}>Contribute to Project</Link>
              </MenuItem>
            </Menu>
            <Box
              style={{
                width: "40px", 
                height: "40px", 
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid #4CAF50", 
                fontSize: "16px", 
                color: "#4CAF50", 
                fontFamily: 'Outfit',
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => setProfileBar(!profileBar)}
            >
              {userInitials}
            </Box>
          </Box>
          
         
          <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items">
              <div className="price-container">
                <Typography style={{ fontFamily: "Outfit", color: "black" }}>
                  PRICE RANGE:{" "}
                </Typography>
                <div className="price-minmax-container">
                  <InputBase
                    placeholder="Min"
                    onChange={(e) => setMinPrice(e.target.value)}
                    className="min-container"
                  />
                  <InputBase
                    placeholder="Max"
                    onChange={(e) => setMaxPrice(e.target.value)}
                    className="max-container"
                  />
                </div>
              </div>
              <div className="date-container">
                <Typography style={{ fontFamily: "Outfit", color: "black" }}>
                  DATE RANGE:{" "}
                </Typography>
                <div className="date-fromto-container">
                  <InputBase
                    type="date"
                    placeholder="From"
                    onChange={(e) => setFrom(e.target.value)}
                    className="min-container"
                  />
                  <InputBase
                    type="date"
                    placeholder="To"
                    onChange={(e) => setTo(e.target.value)}
                    className="max-container"
                  />
                </div>
              </div>
            </ul>
          </nav>
          {currentUser&&
          <nav className={profileBar ? "profile-menu active" : "profile-menu"}>
          <div className="profile-menu-container">
            <Link 
              to="/user/account-settings" 
              style={{ textDecoration: 'none' }}
            >
              <Button 
                className="profile-menu-button"
                style={{ 
                  fontFamily: "Outfit", 
                  color: "black",
                  width: "100%",
                  justifyContent: "flex-start",
                  padding: "10px 15px",
                  textTransform: "none"
                }}
              >
                {currentUser.displayName}
              </Button>
            </Link>
            <Button
              className="profile-menu-button"
              onClick={handleLogout}
              style={{ 
                fontFamily: "Outfit", 
                color: '#2E7D32',
                fontWeight: '500'
              }}
            >
              <span style={{ marginRight: "8px" }}>Logout</span>
              <LogoutIcon style={{ fontSize: "20px" }}/>
            </Button>
          </div>
        </nav>}

      </StyledToolbar>
    </AppBar>
  );
};

export default PlainNavbar;
