import "../styles/Addroom.css"
import { useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import {storage} from "../firebase";
import { getDownloadURL, uploadBytesResumable, ref } from 'firebase/storage';
import { useNavigate } from "react-router-dom";
import PlainNavbar from "./User/PlainNavbar";
import {
  useJsApiLoader,
  useLoadScript,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'
import {SkeletonText} from '@chakra-ui/react'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"
import zIndex from "@mui/material/styles/zIndex";
import axiosInstance from "../apis/backend"

function Addroom() {
  const [isDragging, setIsDragging] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [message, setMessage] = useState(false);
  const fileInputRef = useRef(null);
  const [apartment, setApartment] = useState('');
  const [desc, setDesc] = useState('');
  const [price, setPrice] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [files, setFiles] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);
  const [progress, setProgress] = useState([]);
  const [address, setAddress] = useState();
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();


  const { isLoaded } = useLoadScript({
      googleMapsApiKey: "AIzaSyBa9IaEPXg4jOFa7o6c40E5J0KdIzzTo78",
      libraries: ["places"],
  });

    useEffect(() => {
        const uploadFiles = () => {
            setIsUploading(true);
            const uploadPromises = files.map((file, index) => {
                return new Promise((resolve, reject) => {
                    const name = new Date().getTime() + file.name;
                    const storageRef = ref(storage, name);
                    const uploadTask = uploadBytesResumable(storageRef, file);

                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            setProgress((prevProgress) => {
                                const newProgress = [...prevProgress];
                                newProgress[index] = progress;
                                return newProgress;
                            });
                        },
                        reject,
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref)
                                .then((downloadURL) => {
                                    setImgUrls((prevImgUrls) => [...prevImgUrls, downloadURL]);
                                    resolve();
                                })
                                .catch(reject);
                        }
                    );
                });
            });

            Promise.all(uploadPromises)
                .then(() => {
                    setIsUploading(false);
                    console.log("All uploads completed");
                })
                .catch((error) => {
                    console.error("Upload error:", error);
                    setIsUploading(false);
                });
        };

        if (files.length > 0) {
            uploadFiles();
        }
    }, [files]);

  const selectFiles = () => {
      fileInputRef.current.click();
  };

  const onFileSelect = (event) => {
      const files = Array.from(event.target.files);

      if (files.length === 0) return;
      const newImages = files.filter((file) => file.type.split('/')[0] === 'image');
      setFiles(newImages);
      newImages.forEach((file) => {
          if (!images.some((e) => e.name === file.name)) {
              setImages((prevImages) => [
                  ...prevImages,
                  {
                      name: file.name,
                      url: URL.createObjectURL(file),
                  },
              ]);
          }
      });
  };

  const deleteImage = (index) => {
      setImages((prevImages) => prevImages.filter((_, i) => i !== index));

      const updatedFiles = [...files];
      updatedFiles.splice(index, 1);
      setFiles(updatedFiles);
      setSelectedFiles(updatedFiles);
  };

  const onDragOver = (event) => {
      event.preventDefault();
      setIsDragging(true);
      event.dataTransfer.dropEffect = "copy";
  };

  const onDrop = (event) => {
      event.preventDefault();
      setIsDragging(false);
      const files = Array.from(event.dataTransfer.files);
      const newImages = files.filter((file) => file.type.split('/')[0] === 'image');
      setFiles((prevFiles) => [...prevFiles, ...newImages]);
      newImages.forEach((file) => {
          if (!images.some((e) => e.name === file.name)) {
              setImages((prevImages) => [
                  ...prevImages,
                  {
                      name: file.name,
                      url: URL. createObjectURL(file),
                  },
              ]);
          }
      });
  };

  const onDragLeave = (event) => {
      event.preventDefault();
      setIsDragging(false);
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      if (address == null) {
        setIsAddressValid(false);
        return;
      }

      if (isUploading) {
        alert("Please wait for images to finish uploading");
        return;
      }
      
      const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
      const uid = userFromLocalStorage ? userFromLocalStorage.uid : null;
      const listing = { uid: uid, apartment, address, desc, price, from, to, img: imgUrls ,isDeleted:false};

      try {
        await axiosInstance.post(`/addRoom/${uid}`, listing);
        console.log('New room added');
        navigate('/');
      } catch (error) {
        console.error('Error:', error);
      }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="addroom-container">
    <PlainNavbar/>
    <div className="addroom-content">
          <form 
            onSubmit={handleSubmit}
            className="responsive-form"
          > 
            {/* Left Column */}
              <div className="left-column">
                <h2 style={{ 
                    fontFamily: "'Inter', sans-serif", 
                    marginBottom: "17px",
                    fontSize: "24px",
                    color: "#2D3748",
                    fontWeight: "600"
                    }}>Add a New Posting</h2>

                <div style={{ marginBottom: "17px" }}>
                    <label style={{ 
                        fontFamily: "'Inter', sans-serif", 
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#4A5568",
                        marginBottom: "8px",
                        display: "block"
                     }} >Apartment Name</label>
                        <input 
                            type="text" 
                            required 
                            value={apartment}
                            onChange={(e) => setApartment(e.target.value)}
                            className="form-input"
                        />
                </div>
                <div style={{ marginBottom: "17px" , }}>
                    <label style={{ 
                        fontFamily: "'Inter', sans-serif", 
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#4A5568",
                        marginBottom: "8px",
                        display: "block"
                     }}>Address </label>
                    <div><PlacesAutocomplete setAddress={setAddress}/></div>
                </div>
                <div style={{ marginBottom: "17px" }}>
                    <label style={{ 
                        fontFamily: "'Inter', sans-serif", 
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#4A5568",
                        marginBottom: "8px",
                        display: "block"
                    }}>Price per month</label>
                    <input
                        type="number"
                        required
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="form-input"
                    />
                </div>
                <div style={{ marginBottom: "17px" }}>
                    <label style={{ 
                        fontFamily: "'Inter', sans-serif", 
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#4A5568",
                        marginBottom: "8px",
                        display: "block"
                     }}>From</label>
                    <input
                        type="date"
                        required
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                        className="form-input"
                    />
                </div>
                <div style={{ marginBottom: "17px" }}>
                    <label style={{ 
                        fontFamily: "'Inter', sans-serif", 
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#4A5568",
                        marginBottom: "8px",
                        display: "block"
                     }}>To</label>
                    <input
                        type="date"
                        required
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                        className="form-input"
                    />
                </div>
                <div style={{ marginBottom: "17px" }}>
                    <label style={{ 
                        fontFamily: "'Inter', sans-serif", 
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#4A5568",
                        marginBottom: "8px",
                        display: "block"
                     }}>Contact me</label>
                    <textarea
                    required
                    value={desc}
                    placeholder="How does the person contact you? eg: email, phone number, social media etc."
                    onChange={(e) => setDesc(e.target.value)}
                    className="form-textarea"
                    ></textarea>
                </div>
                {!isAddressValid && <span style={{ 
                    color: "#E53E3E", 
                    fontSize: "14px",
                    marginBottom: "10px",
                    display: "block"
                 }}>Address is required</span>}
                <button type="submit" className="submit-button-left">
                    Add Posting
                </button>
              </div>
              
             
             {/* Right Column */}
             <div className="right-column">
                <div style={{
                    padding: "24px",
                    borderRadius: "8px",
                    backgroundColor: "white",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)"}}
                >
                        <div style={{
                            marginBottom: "20px",
                            borderBottom: "1px solid #E2E8F0",
                            paddingBottom: "16px" }}>
                            <p style={{ 
                                fontFamily: "'Inter', sans-serif",
                                fontWeight: "500",
                                color: "#2D3748",
                                fontSize: "16px",
                                margin: 0
                             }}>Upload images of your apartment.</p>
                        </div>
                        <div onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}
                            style={{
                                height: "300px",
                                border: "2px dashed #9AE6B4",
                                borderRadius: "8px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#F0FFF4",
                                transition: "all 0.3s ease",
                                cursor: "pointer"
                            }}
                        >
                            {isDragging ? (
                                <span style={{ 
                                    fontFamily: "'Inter', sans-serif",
                                    fontWeight: "500",
                                    color: "#38A169",
                                    fontSize: "16px"
                                 }} className='select'>Drop images here</span>
                            ) : (
                                <>  
                                    <span style={{ 
                                        fontFamily: "'Inter', sans-serif",
                                        color: "#4A5568",
                                        marginBottom: "8px"
                                     }}>
                                        Drag & Drop images here or
                                    </span> 
                                    <span style={{ 
                                        fontFamily: "'Inter', sans-serif",
                                        color: "#38A169",
                                        fontWeight: "500",
                                        cursor: "pointer"
                                     }} role='button' onClick={selectFiles}>
                                        Browse
                                    </span>
                                </>
                            )}
                            <input name='file' type='file' multiple ref={fileInputRef} onChange={onFileSelect} style={{ display: "none" }}></input>
                        </div>
                        <div style={{
                            marginTop: "20px",
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
                            gap: "16px",
                            maxHeight: "200px",
                            overflowY: "auto",
                            padding: "8px"
                        }}>
                            {
                                images.map((image, index) => (
                                    <div className='image' key={index} style={{
                                        position: "relative",
                                        width: "100%",
                                        paddingBottom: "100%",
                                        borderRadius: "6px",
                                        overflow: "hidden"
                                      }}>
                                        <span style={{ 
                                            position: "absolute",
                                            top: "4px",
                                            right: "4px",
                                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                                            color: "white",
                                            width: "24px",
                                            height: "24px",
                                            borderRadius: "50%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            zIndex: 10
                                         }} className='delete' onClick={() => deleteImage(index)}>&times;</span>
                                        <img src={image.url} alt={image.name} 
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover"
                                            }}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        {message && <div>Upload successful</div>}
                    </div>
                    <button type="submit" className="submit-button-right">
                        Add Posting
                    </button>
                </div>
          </form>      
      </div>
    </div>
  );
}



const PlacesAutocomplete=({setAddress})=>{
  const {
    ready,
    value,
    setValue,
    suggestions:{status, data},
    clearSuggestions,
  }= usePlacesAutocomplete();

  const handleSelect= async (address) =>{
    setValue(address, false)
    clearSuggestions()
    const results= await getGeocode({address})
    const {lat,lng} = await getLatLng(results[0])
    setAddress({address,lat,lng})
  }
  return (
    <div style={{ position: "relative" }}>
      <input 
        type="text" 
        value={value} 
        onChange={(e) => setValue(e.target.value)} 
        disabled={!ready} 
        className="form-input"
        placeholder="Enter address."
      />
      {status === "OK" && (
        <ul style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
            marginTop: "4px",
            padding: "8px 0",
            zIndex: 1000,
            maxHeight: "200px",
            overflowY: "auto",
            border: "1px solid #E2E8F0"
          }}>
          {data.map(({ place_id, description }) => (
            <li key={place_id} onClick={() => handleSelect(description)} style={{
                padding: "10px 16px",
                cursor: "pointer",
                fontSize: "14px",
                color: "#4A5568",
                transition: "background-color 0.2s",
                "&:hover": {
                  backgroundColor: "#F7FAFC"
                }
              }}

              onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#F7FAFC"}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = "transparent"}
            >
              {description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
} 


export default Addroom;
